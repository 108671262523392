import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ErrorMessage } from "formik";

import { StyledFormControl, StyledError } from "@components";
import { IHandleBlur } from "@interfaces";

interface IProps {
    handleChange: (value?: any) => void;
    handleBlur: IHandleBlur;
    value: string | number | null; // Only pass the countryCode (id)
    name: string;
    label: string;
    focus?: boolean;
    defaultValue?: string | Date | null;
    required?: boolean;
    userId?: string | number;
    listValueMembers: {
        id: string | number;
        label: string;
    }[];
}

export const SelectCommon = (props: IProps) => {
    const { name, label, value, handleChange, handleBlur, userId, listValueMembers } = props;

    return (
        <>
            <StyledFormControl>
                <Autocomplete
                    options={listValueMembers}
                    getOptionLabel={(option) => option.label}
                    id={"" + name + userId}
                    fullWidth
                    onChange={(event, newValue) => {
                        handleChange(newValue ? newValue.id : null);
                    }}
                    value={listValueMembers.find((item) => item.id === value) || null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={`${label} *`}
                            margin="normal"
                        />
                    )}
                    onBlur={handleBlur(name)}
                />
            </StyledFormControl>
            <ErrorMessage name={name} component={StyledError} />
        </>
    );
};
