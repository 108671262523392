import { InputLabel, Input, InputAdornment, IconButton } from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import { ErrorMessage } from "formik";
import React from "react";
import styled from "styled-components";

import { StyledFormControl, StyledError } from "@components";
import { IHandleChange, IHandleBlur } from "@interfaces";

interface InputComponentProp {
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    value: string | Date | null;
    name: string;
    label: string;
    type: string;
    focus?: boolean;
    defaultValue?: string | Date | null | undefined;
    required?: boolean;
    userId?: string | number;
    placeHolder?: string;
    img?: string;
    error?: string;
    className?: string;
}

export const InputCommon = (props: InputComponentProp) => {
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const {
        name,
        label,
        value,
        type,
        focus,
        handleChange,
        handleBlur,
        defaultValue,
        placeHolder,
        // required,
        userId,
        img,
        error,
        className,
    } = props;

    return (
        <>
            <StyledFormControl variant="standard">
                <StyledLabel htmlFor={"" + name + userId}>{label}</StyledLabel>
                <Input
                    className={className}
                    onChange={handleChange(name)}
                    value={value || ""}
                    fullWidth
                    id={"" + name + userId}
                    name={name}
                    onBlur={handleBlur(name)}
                    type={showPassword ? "text" : type}
                    autoComplete={name}
                    autoFocus={focus}
                    defaultValue={defaultValue}
                    placeholder={placeHolder}
                    style={{
                        color: value ? "#090A18" : "transparent",
                    }}
                    endAdornment={
                        type === "password" ? (
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="start"
                                >
                                    {showPassword ? (
                                        <VisibilityOutlined />
                                    ) : (
                                        <VisibilityOffOutlined />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ) : img ? (
                            <InputAdornment position="end">
                                <EmailIcon />
                            </InputAdornment>
                        ) : null
                    }
                />
            </StyledFormControl>
            {error ? (
                <StyledError>{error}</StyledError>
            ) : (
                <ErrorMessage name={name} component={StyledError} />
            )}{" "}
        </>
    );
};

const StyledLabel = styled(InputLabel)`
    span {
        color: ${(p) => p.theme.colors.red};
    }
`;
