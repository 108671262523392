import { Link } from "react-router-dom";
import styled from "styled-components";

import {
    StyledHeader,
    IconCart,
    IconUser,
    MenuHeaderComponent,
    IconHeart,
    IconFileText,
    IconLock,
    // IconChain,
    StyledButton,
    StyledBorderButton,
} from "@components";
import {
    PATH_CART,
    PATH_HOME,
    PATH_FAVORITES,
    PATH_BOOKING,
    PATH_QUOTES,
    PATH_MY_ACCOUNT,
    PATH_UPDATE_PASSWORD,
    // PATH_LINK_AGENT_ACCOUNT,
    PATH_SIGNUP,
    PATH_LOGIN,
} from "@routes";
import { selectAccount, selectAuth, useAppSelector } from "@redux";
import { breakpoint, theme } from "@utilities";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";

export interface IHeaderComponent {
    page?: string;
    isScrollUp?: boolean;
    headerFix?: boolean;
    toggleShowMenu: () => void;
    isShowMenu: boolean;
    productCount: number;
}

export const HeaderComponent = (props: IHeaderComponent) => {
    const { toggleShowMenu, isShowMenu, productCount, isScrollUp, headerFix } = props;
    const { jwt } = useAppSelector(selectAuth);
    const { account } = useAppSelector(selectAccount);
    const [isSignedIn, setIsSignedIn] = useState(!_.isNil(jwt) && !_.isNil(account));

    useEffect(() => {
        setIsSignedIn(!_.isNil(jwt) && !_.isNil(account));
    }, [jwt]);
    useEffect(() => {
        setIsSignedIn(!_.isNil(jwt) && !_.isNil(account));
    }, [account]);

    const listMenuItems = [
        {
            icon: IconUser,
            title: "Update My Details",
            link: PATH_MY_ACCOUNT,
        },
        {
            icon: IconHeart,
            title: "Favourites",
            link: PATH_FAVORITES,
        },
        {
            icon: IconFileText,
            title: "Bookings",
            link: PATH_BOOKING,
        },
        {
            icon: IconCart,
            title: "Quotes",
            link: PATH_QUOTES,
        },
        {
            icon: IconLock,
            title: "Update Password",
            link: PATH_UPDATE_PASSWORD,
        },
        // {
        //     icon: IconChain,
        //     title: "Link Agent Account",
        //     link: PATH_LINK_AGENT_ACCOUNT,
        // },
    ];
    return (
        <StyledHeader {...props}>
            <div className="backgroundHeader"></div>
            <div className="wrapper">
                <Link className="logo" to={PATH_HOME}>
                    <img
                        src="/images/logos/FreelanceTravel_Primary_Logo_Rev_(2)_(da3453ef-44bb-4946-a8ef-4ee1a8a33e3c).svg"
                        alt="logo"
                        className="logoItem logoWhite"
                    />
                    <img
                        src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg"
                        alt="logo"
                        className="logoItem logoColor"
                    />
                </Link>

                {!isSignedIn ? (
                    <ButtonWrapper>
                        {isScrollUp || !headerFix ? (
                            <NavLink style={{ width: "100%" }} to={PATH_LOGIN}>
                                <StyledBorderButton
                                    borderRadius="4px"
                                    color="transparent"
                                    textColor={theme.colors.main}
                                    style={{ marginTop: "0px" }}
                                >
                                    Log In
                                </StyledBorderButton>
                            </NavLink>
                        ) : (
                            <NavLink style={{ width: "100%" }} to={PATH_LOGIN}>
                                <StyledBorderButton
                                    borderColor="#FFFFFF"
                                    borderRadius="4px"
                                    color="transparent"
                                    style={{ marginTop: "0px" }}
                                >
                                    Log In
                                </StyledBorderButton>
                            </NavLink>
                        )}
                        <NavLink style={{ width: "100%" }} to={PATH_SIGNUP}>
                            <StyledButton
                                style={{ marginTop: "0px" }}
                                borderRadius="4px"
                                color={["#436CB2 0%", "#28B7C3 100%"]}
                            >
                                Sign up
                            </StyledButton>
                        </NavLink>
                    </ButtonWrapper>
                ) : (
                    <div className="cart">
                        {/* {!account?.is_agent_integrated && (
                            <UpgradeButtonWrapper>
                                <NavLink to={PATH_LINK_AGENT_ACCOUNT}>
                                    <StyledButton
                                        style={{ marginTop: "0px", height: "40px" }}
                                        borderRadius="40px"
                                        color={["#00EEAB 0%", "#039CE2 100%"]}
                                    >
                                        Upgrade
                                    </StyledButton>
                                </NavLink>
                            </UpgradeButtonWrapper>
                        )} */}
                        <div className="cartIconWrapper">
                            <Link to={PATH_CART}>
                                {productCount > 0 ? (
                                    <span className="number">{productCount}</span>
                                ) : (
                                    <></>
                                )}
                                <IconCart />
                            </Link>
                        </div>
                        <div className="userIconWrapper" onClick={toggleShowMenu}>
                            <IconUser />
                            {isShowMenu ? <OverLay></OverLay> : <></>}
                            {isShowMenu ? (
                                <MenuHeaderComponent listMenuItems={listMenuItems} />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </StyledHeader>
    );
};

const OverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
`;

const ButtonWrapper = styled.div`
    display: flex;
    width: 300px;
    gap: 20px;
    position: relative;
    ${breakpoint.breakOnlyMobile`
        width : 50%;
    `}
`;
// const UpgradeButtonWrapper = styled.div`
//     width: 90px;
//     margin-right: 40px;
//     position: relative;
// `;
