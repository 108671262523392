import styled from "styled-components";
import { breakpoint, theme } from "@utilities";

interface IProps {
    color?: string | string[];
    disabled?: boolean;
    icon?: string;
    textColor?: string;
    borderRadius?: string;
    borderColor?: string;
}

function backgroundColor(props: IProps) {
    const baseColor = "#31b4b9";
    if (props.disabled) {
        if (props.borderColor) {
            return "transparent";
        }
        return "#BDBDBD";
    }

    if (Array.isArray(props.color) && props.color.length > 1) {
        const gradientColors = props.color.join(", ");
        return `linear-gradient(90deg, ${gradientColors})`;
    } else if (Array.isArray(props.color) && props.color.length === 1) {
        return props.color[0];
    } else if (props.color && typeof props.color === "string") {
        return props.color;
    }

    return baseColor;
}

function textColor(props: IProps) {
    if (props.disabled) {
        if (props.borderColor && props.textColor) {
            return "#BDBDBD";
        }
    }
    return props.textColor ?? "#FFFFFF";
}

function borderColor(props: IProps) {
    if (props.disabled) {
        return "#BDBDBD";
    }
    return props.borderColor ?? theme.colors.main;
}

function borderRadius(props: IProps) {
    return props.borderRadius ?? "";
}

export const StyledButton = styled.button<IProps>`
    border: none;
    width: 100%;
    height: 54px;
    background: ${(props) => backgroundColor(props)};
    color: ${(props) => textColor(props)};
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    margin-top: 20px;
    border-radius: ${(props) => borderRadius(props)};
    gap: 8px;

    img {
        width: 24px;
        height: 24px;
    }

    ${breakpoint.breakTablet`
        font-size: 16px;
    `}

    ${breakpoint.breakIpadPro`
        font-size: 16px;
    `}
    ${breakpoint.breakOnlyMobile`
        height: 40px;
    `}
`;

export const StyledErrorButton = styled(StyledButton)`
    background: ${(props) => props.theme.colors.red};
`;

export const StyledBorderButton = styled(StyledButton)`
    background: ${(props) => backgroundColor(props)};
    border: ${(props) => `${borderColor(props)} 2px solid`};
`;

export const StyledUnBackgroundButton = styled(StyledButton)`
    background-color: transparent;
    color: ${(p) => p.theme.colors.main};
`;

export const CancelButton = styled(StyledButton)`
    background-color: rgba(244, 63, 63, 0.1);
    color: ${(p) => p.theme.colors.red};
`;
