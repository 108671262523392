import * as Yup from "yup";

export const customerDetailSchema = Yup.object().shape({
    firstName: Yup.string()
        .trim()
        .required("This field is required!")
        .max(50, "Cannot be longer than 50 characters!"),
    lastName: Yup.string()
        .trim()
        .required("This field is required!")
        .max(50, "Cannot be longer than 50 characters!"),
    phoneNumber: Yup.string().trim().required("This field is required!"),
    email: Yup.string()
        .trim()
        .required("This field is required!")
        .email("Email is not valid")
        .max(255, "Cannot be longer than 255 characters!"),
    dateOfBirth: Yup.string().trim().required("This field is required!"),
    postalCode: Yup.string()
        .trim()
        .required("This field is required!")
        .max(255, "Cannot be longer than 255 characters!"),
    countryCode: Yup.string().trim().required("This field is required!"),
});
