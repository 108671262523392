import { css } from "styled-components";

export const theme = {
    colors: {
        main: "#31b4b9",
        main_disable: "#83bdbf",
        sub_2: "#C5C7C9",
        gray: "#F6F6F6",
        gray_1: "#333333",
        gray_2: "#4f4f4f",
        gray_3: "#828282",
        gray_4: "#BDBDBD",
        gray_5: "#E0E0E0",
        gray_5_5: "#e9e9e9",
        gray_6: "#F2F2F2",
        red: "#F43F3F",
        black: "#090A18",
    },
    breakPoints: {
        breakSmall: "320px",
        breakMobile: "375px",
        breakMobileMedium: "575px",
        breakTablet: "767px",
        breakOnlyMobile: "768px",
        breakMedium: "980px",
        breakIpadPro: "1024px",
        breakLarge: "1200px",
        breakBig: "1366px",
    },
    widths: {
        wide_650: "650px",
        wide_700: "700px",
        wide_900: "900px",
        wide_1110: "1110px",
    },
};

type cssParams = Parameters<typeof css>;

export const breakpoint = {
    breakSmall: (...args: cssParams) => css`
        @media (min-width: ${theme.breakPoints.breakSmall}) and (max-width: ${theme.breakPoints
                .breakMobile}) {
            ${css(...args)}
        }
    `,
    breakMobile: (...args: cssParams) => css`
        @media (min-width: ${theme.breakPoints.breakMobile}) and (max-width: ${theme.breakPoints
                .breakMobileMedium}) {
            ${css(...args)}
        }
    `,
    breakMobileMedium: (...args: cssParams) => css`
        @media (min-width: ${theme.breakPoints.breakMobileMedium}) and (max-width: ${theme
                .breakPoints.breakTablet}) {
            ${css(...args)}
        }
    `,
    breakTablet: (...args: cssParams) => css`
        @media (min-width: ${theme.breakPoints.breakTablet}) {
            ${css(...args)}
        }
    `,
    breakMedium: (...args: cssParams) => css`
        @media (min-width: ${theme.breakPoints.breakMedium}) {
            ${css(...args)}
        }
    `,
    breakIpadPro: (...args: cssParams) => css`
        @media (min-width: ${theme.breakPoints.breakIpadPro}) {
            ${css(...args)}
        }
    `,
    breakIpad: (...args: cssParams) => css`
        @media (min-width: ${theme.breakPoints.breakTablet}) and (max-width: ${theme.breakPoints
                .breakIpadPro}) {
            ${css(...args)}
        }
    `,
    breakLarge: (...args: cssParams) => css`
        @media (min-width: ${theme.breakPoints.breakLarge}) {
            ${css(...args)}
        }
    `,
    breakBig: (...args: cssParams) => css`
        @media (min-width: ${theme.breakPoints.breakBig}) {
            ${css(...args)}
        }
    `,
    breakOnlyMobile: (...args: cssParams) => css`
        @media (max-width: ${theme.breakPoints.breakTablet}) {
            ${css(...args)}
        }
    `,
    breakOnlyMobile768: (...args: cssParams) => css`
        @media (max-width: ${theme.breakPoints.breakOnlyMobile}) {
            ${css(...args)}
        }
    `,
};
