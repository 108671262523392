import { IAccountInfo } from "@interfaces";
import ftApiClient from "./ftApiClient";

export const accountApi = {
    userProfile: () => {
        const url = "/user/detail";
        return ftApiClient.get(url);
    },
    updateUserDetail: (params: IAccountInfo) => {
        const url = "/user/detail";
        return ftApiClient.put(url, params);
    },
};
