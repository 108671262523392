import { useState } from "react";
import styled from "styled-components";

const CopyButton = ({ value }: { value?: string | null }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        if (value) {
            navigator.clipboard.writeText(value);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Hide tooltip after 2 seconds
        }
    };

    return (
        <CopyWrapper>
            <button onClick={handleCopy}>
                <img src="/images/copy.svg" alt="copy" />
            </button>
            {copied && <Tooltip>Copied!</Tooltip>}
        </CopyWrapper>
    );
};

export default CopyButton;

const CopyWrapper = styled.div`
    position: relative;
    display: inline-block;
    margin-left: 8px; /* Adds spacing between text and button */
`;

const Tooltip = styled.div`
    position: absolute;
    top: -25px;
    left: 100%;
    background: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0.9;
    z-index: 100;
`;
