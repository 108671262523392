import { PATH_MY_ACCOUNT } from "@routes";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const AddMyDetailsComponent = () => {
    return (
        <Container>
            <Title>Details</Title>
            <DetailsBox>
                <AddDetailsLink to={PATH_MY_ACCOUNT}>
                    <img src="/images/plus.svg" alt="plus" />
                    Add My details
                </AddDetailsLink>
            </DetailsBox>
        </Container>
    );
};

const Container = styled.div`
    background-color: #fff;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
`;

const Title = styled.h1``;

const DetailsBox = styled.div`
    border: 1px solid #ade1e3;
    border-radius: 10px;
    padding: 34px 0;
    gap: 10px;
    display: flex;
    justify-content: center;
`;

const AddDetailsLink = styled(NavLink)`
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 10px;
`;
