import * as Yup from "yup";

export const accountSchema = Yup.object().shape({
    first_name: Yup.string()
        .trim()
        .required("This field is required!")
        .max(50, "This field is cannot be longer than 50 characters!"),
    last_name: Yup.string()
        .trim()
        .required("This field is required!")
        .max(50, "This field is cannot be longer than 50 characters!"),
    phone_number: Yup.string().trim().required("This field is required!"),
    date_of_birth: Yup.string().trim().required("This field is required!"),
    post_code: Yup.string()
        .trim()
        .required("This field is required!")
        .max(255, "Enter not allow too 255 character!"),
    country_code: Yup.string().trim().required("This field is required!"),
});
