import { useEffect } from "react";
import { InputBase, TextField } from "@mui/material";
import { IFareCart, IOptionalField } from "@interfaces";
import Dropdown from "./CustomDropdown";
import { FieldProps, useField, useFormikContext } from "formik";
import _ from "lodash";

interface IBookingDataFieldProps extends FieldProps {
    optionalField: IOptionalField;
    item: IFareCart;
}

export const BookingDataField = (props: IBookingDataFieldProps) => {
    const { optionalField, item, ...rest } = props;
    const [field] = useField(rest.field.name); 
    const { setFieldValue } = useFormikContext(); 
    const fieldName = rest.field.name.split(".").pop();
    const initialValue = fieldName ? item.booking_data?.optionalData?.[fieldName] : undefined;
    const handleChange = (newValue: any) => {
        setFieldValue(rest.field.name, newValue);
    };

    useEffect(() => {
        if (!_.isNaN(initialValue) && (_.isNaN(field.value) || _.isEmpty(field.value))) {
            setFieldValue(rest.field.name, initialValue);
        }
    }, [initialValue]);

    switch (optionalField.type) {
        case "text":
            return (
                <TextField
                    {...rest.field}
                    fullWidth
                    sx={{ fontWeight: "bold" }}
                    value={field.value || ""}
                    onChange={(e) => {
                        handleChange(e.target.value);
                    }}
                />
            );

        case "select":
            return (
                <Dropdown
                    options={optionalField.options.map((option) => ({
                        label: option,
                        value: option,
                    }))}
                    value={field.value || optionalField.options[0]} // Use field.value from Formik
                    onChange={(value) => handleChange(value)} // Pass the selected value to handleChange
                />
            );

        case "number":
            return (
                <InputBase
                    {...rest.field}
                    type="number"
                    inputProps={{ min: 0 }}
                    value={field.value || ""} // Use field.value from Formik
                    onChange={(e) => handleChange(Number(e.target.value))} // Pass the numeric value to handleChange
                />
            );

        default:
            return null;
    }
};
