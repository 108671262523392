import { useParams } from "react-router-dom";
import styled from "styled-components";
import { StyledBookingDetail, BreadcrumbsCommon, StyledBorderButton } from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_BOOKING, PATH_BOOKING_DETAIL, PATH_HOME } from "@routes";
import { getTour, selectBooking, selectTour, useAppDispatch, useAppSelector } from "@redux";
import moment from "moment";
import _ from "lodash";
import { breakpoint, theme } from "@utilities";
import { useEffect } from "react";
import parse from "html-react-parser";
import CopyButton from "src/Module/CopyButton";

export const BookingDetail = () => {
    const { id, tripId } = useParams<{ id: string; tripId: string }>();
    const dispatch = useAppDispatch();
    const tourRedux = useAppSelector(selectTour);

    const breadcrumbsModel = [
        { title: "Home", link: PATH_HOME },
        { title: "Bookings", link: PATH_BOOKING },
        { title: "BookingDetail", link: `${PATH_BOOKING_DETAIL}/${id}` },
    ];

    const { listBooking } = useAppSelector(selectBooking);
    const Booking = _.find(listBooking, { bookingReference: id });
    const productDetails = _.find(Booking?.products, { tripId: Number(tripId) });

    const date = Booking?.purchaseDate ? new Date(Booking.purchaseDate) : null;
    const dateFormat = date ? moment(date).format("DD/MM/YYYY") : "N/A";

    const handleShowMore = () => {
        if (Booking?.customerOrderPageUrl) {
            window.open(Booking.customerOrderPageUrl, "_blank");
        }
    };
    useEffect(() => {
        dispatch(getTour(tripId!));
    }, [tripId]);

    return (
        <AppModuleLayout>
            <StyledBookingDetail isLogin={true}>
                <div className="wrapper">
                    <div className="breadCrumbs">
                        <BreadcrumbsCommon data={breadcrumbsModel} />
                    </div>
                    <ListCarts>
                        <div className="HeadingContainer">
                            <Heading>Booking Details</Heading>
                            <StyledBorderButton
                                className="viewMore"
                                borderRadius="4px"
                                textColor={theme.colors.main}
                                color="transparent"
                                borderColor={theme.colors.main}
                                onClick={handleShowMore}
                            >
                                View more Details 
                                <img src="/images/ViewMore.svg" alt="view More" />
                            </StyledBorderButton>
                        </div>
                        <GridContainer>
                            <LeftPanel>
                                <ProductContainer>
                                    <div className="img">
                                        <img src={tourRedux.tour?.productImagePath} alt="GGwp" />
                                    </div>
                                    <div className="productInfo">
                                        <div className="details">
                                            <div className="detailName">{productDetails?.name}</div>
                                            <div className="fareType">FareType : </div>
                                        </div>
                                        <Separator />
                                        <div className="guestInfo">
                                            <div>Guests : {productDetails?.qty}</div>
                                            <div>Date : </div>
                                        </div>
                                    </div>
                                </ProductContainer>
                                <CustomerContainer>
                                    <Head>Customer Details</Head>
                                    <div className="detailBox">
                                        <div className="details">
                                            <DetailHeading>Name</DetailHeading>
                                            <Value>{productDetails?.redeemers[0].name}</Value>
                                        </div>
                                        <div className="details">
                                            <DetailHeading>Mobile Phone</DetailHeading>
                                            <Value>{productDetails?.redeemers[0].phone}</Value>
                                        </div>
                                        <div className="details">
                                            <DetailHeading>Email</DetailHeading>
                                            <Value>{productDetails?.redeemers[0].email}</Value>
                                        </div>
                                    </div>
                                </CustomerContainer>
                                <BookingContainer>
                                    <Head>Booking Details</Head>
                                    <div className="detailBox">
                                        <div className="details">
                                            <DetailHeading>Booking Date</DetailHeading>
                                            <Value>{dateFormat}</Value>
                                        </div>
                                        <div className="details">
                                            <DetailHeading>Start Time</DetailHeading>
                                            <Value>{productDetails?.redeemers[0].phone}</Value>
                                        </div>
                                        <div className="details">
                                            <DetailHeading>eBookingRef</DetailHeading>
                                            <Value>
                                                {
                                                    productDetails?.redeemers[0].bookingDetails[0]
                                                        .eBookingReferenceId
                                                }
                                            </Value>
                                        </div>
                                    </div>
                                    <div className="suppliers">
                                        <div className="details">
                                            <DetailHeading>supplierName:</DetailHeading>
                                            <Value>{productDetails?.supplier.name}</Value>
                                        </div>
                                        <div className="details">
                                            <DetailHeading>SupplierPhone:</DetailHeading>
                                            <Value>{productDetails?.supplier.phone}</Value>
                                        </div>
                                    </div>
                                </BookingContainer>
                                <VoucherComments>
                                    <Head>Voucher Comments</Head>
                                    <div style={{ fontSize: "14px" }}>
                                        {parse(productDetails?.instructions || "")}
                                    </div>
                                </VoucherComments>
                                <ImportantInformation>
                                    <Head>Important Information</Head>
                                    <Value>
                                        No open dated tickets please confirm dates 48 hrs prior to
                                        arrival.
                                    </Value>
                                </ImportantInformation>
                            </LeftPanel>
                            <RightPanel>
                                <Info>
                                    <div>
                                        <DetailHeading>Booking Reference :</DetailHeading>
                                        <div className="reference">
                                            <span className="Value ">
                                                {Booking?.bookingReference || "N/A"}
                                            </span>
                                            <CopyButton value={Booking?.bookingReference} />
                                        </div>
                                    </div>
                                    <div>
                                        <DetailHeading>Voucher Number :</DetailHeading>
                                        <div className="reference">
                                            <span className="Value ">
                                                {productDetails?.voucherNumber || "N/A"}
                                            </span>
                                            <CopyButton value={productDetails?.voucherNumber} />
                                        </div>
                                    </div>
                                    <div>
                                        <DetailHeading>Product ID :</DetailHeading>
                                        <div className="reference">
                                            <span className="Value ">{tripId || "N/A"}</span>
                                            <CopyButton value={tripId} />
                                        </div>
                                    </div>
                                    <div>
                                        <DetailHeading>Booking Agent :</DetailHeading>
                                        <div className="agent">
                                            <h1 className="agentName">{Booking?.agent.name}</h1>
                                            <div>
                                                {Booking?.agent.address1},{Booking?.agent.country}
                                            </div>
                                            <div>{Booking?.agent.phone}</div>
                                            <div>{Booking?.agent.website}</div>
                                        </div>
                                    </div>
                                </Info>
                            </RightPanel>
                        </GridContainer>
                    </ListCarts>
                </div>
            </StyledBookingDetail>
        </AppModuleLayout>
    );
};

const ListCarts = styled.div`
    .HeadingContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .viewMore {
            width: 200px;
        }
    }
`;

const Heading = styled.p`
    font-size: 24px;
    font-weight: bold;
`;

const Head = styled.p`
    font-weight: 600;
`;
const Value = styled.p`
    font-size: 14px;
`;

const DetailHeading = styled.p`
    font-size: 14px;
    color: #6e7375;
`;

const GridContainer = styled.div`
    display: flex;
    margin-bottom: 50px;
    ${breakpoint.breakOnlyMobile`
        display : flex;
        flex-direction: column-reverse;
        gap: 20px;
    `}
`;

const LeftPanel = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    ${breakpoint.breakLarge`
            width: ${(p) => p.theme.widths.wide_1110};
    `}

    .SmallText {
        color: #6e7375;
        font-size: 12px;
    }

    ${breakpoint.breakOnlyMobile`
        width: 100%
     `}
    ${breakpoint.breakTablet`
        background-color: #F2F2F2;
        padding: 0 16px 44px 0;
        flex: 698;

        .infoWrapper {
            background-color: #F2F2F2;
        }

        .MuiInput-root {
            display: none;
        }
    `}

    ${breakpoint.breakIpadPro`
        padding: 0 33px 44px 0;
    `}
    
    ${breakpoint.breakLarge`
        padding: 0 62px 84px 0;
    `}
`;
const ProductContainer = styled.div`
    background-color: #ffffff;
    display: flex;

    .productInfo {
        padding: 25px 16px 25px 34px;
        flex: 1;
        ${breakpoint.breakOnlyMobile`
            padding: 4px 12px;
        `}

        .details {
            display: flex;
            flex-direction: column;
            gap: 20px;
            ${breakpoint.breakOnlyMobile`
                gap: 12px;
            `}

            .detailName {
                font-size: 18px;
                font-weight: bold;
                ${breakpoint.breakOnlyMobile`
                    font-size: 14px;
                `}
            }

            .fareType {
                font-size: 14px;
                font-weight: 600;
                color: #6e7375;
                ${breakpoint.breakOnlyMobile`
                    font-size: 12px;
                `}
            }
        }
        .guestInfo {
            display: flex;
            gap: 38px;
            font-size: 14px;
            font-weight: 500;
        }
    }

    .img {
        width: 190px;
        height: 165px;
        img {
            height: 100%;
            width: 100%;
        }
        ${breakpoint.breakOnlyMobile`
            width: 108px;
            height: 114px;
        `}
    }
`;
const CustomerContainer = styled.div`
    background-color: #ffffff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .detailBox {
        display: flex;
        justify-content: space-between;
        ${breakpoint.breakOnlyMobile`
            flex-direction: column;
            gap : 10px;
        `}
        .details {
            display: flex;
            gap: 5px;
            flex-direction: column;
            ${breakpoint.breakOnlyMobile`
                flex-direction: row;
                justify-content : space-between;
            `}
        }
    }
`;

const BookingContainer = styled.div`
    background-color: #ffffff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .detailBox {
        display: flex;
        justify-content: space-between;
        ${breakpoint.breakOnlyMobile`
            flex-direction: column;
            gap : 10px;
        `}
        .details {
            display: flex;
            gap: 5px;
            flex-direction: column;
            ${breakpoint.breakOnlyMobile`
                flex-direction: row;
                justify-content : space-between;
            `}
        }
    }
    .suppliers {
        background-color: #fafafa;
        padding: 10px;
        display: flex;
        gap: 40px;
        ${breakpoint.breakOnlyMobile`
            flex-direction: column;
            gap : 10px;
        `}
        .details{
        display: flex;
            gap: 5px;
            flex-direction: column;
            ${breakpoint.breakOnlyMobile`
                flex-direction: row;
                justify-content : space-between;
            `}
        }
`;

const VoucherComments = styled.div`
    background-color: #ffffff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ImportantInformation = styled.div`
    background-color: #ffffff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Separator = styled.hr`
    border-bottom: 1px solid #e6e9eb;
    margin: 10px 0;
`;

const RightPanel = styled.div`
    display: flex;
    width: 31.5%;
    flex-direction: column;
    .Row {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .ReferenceContainer {
            .reference {
                display: flex;
                align-items: center;
                gap: 10px;
                ${breakpoint.breakOnlyMobile`
                    display: flex;
                    align-items: center;
                    gap : 5px;
                    justify-content: space-between;
                `}
            }
            ${breakpoint.breakOnlyMobile`
                width: 100%;
                display : flex;
                gap: 5px;
                flex-direction : column;
             `}
        }
        .Label {
            width: 170px;
        }
        .Value {
            color: #090a18;
        }
    }
    ${breakpoint.breakOnlyMobile`
       width: 100%
    `}
`;

const Info = styled.div`
    background-color: #fff;
    padding: 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    color: #090a18;
    .reference {
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
    }
    .agent {
        margin-top: 10px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        font-size: 14px;
        .agentName {
            font-size: 16px;
        }
    }
`;
