import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAccountInfo } from "@interfaces";
import { RootState } from ".";

interface IState {
    agentInfo: IAccountInfo;
}
const initialAgent: IAccountInfo = {
    first_name: "",
    last_name: "",
    date_of_birth: "",
    email: "",
    phone_number: "",
    post_code: "",
    country_code: "",
};

const initialState: IState = {
    agentInfo: initialAgent,
};

const agent = createSlice({
    name: "agent",
    initialState: initialState,
    reducers: {
        setAgentInfo: (state, action: PayloadAction<IAccountInfo>) => {
            state.agentInfo = action.payload;
        },
    },
});

export const selectAgent = (state: RootState) => state.agent;

export const { setAgentInfo } = agent.actions;

export default agent.reducer;
