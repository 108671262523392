import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { StyledBorderButton, StyledButton, StyledError } from "@components";
import { AppDispatch, checkPayment } from "@redux";
import { breakpoint } from "@utilities";
import { IFareCart } from "@interfaces";
import { calculatePricingInfo } from "src/Utilities/cartHelper";
interface IProps {
    btnTitle?: string;
    onBtnClick?: () => void;
    page?: string;
    handleSaveCart?: () => void;
    paymentOption?: string;
    cartItems: IFareCart[];
    disableNext: boolean;
    onSaveClick?: () => void;
    isSubmitting?: boolean;
}

export const TotalPriceComponent = memo((props: IProps) => {
    const { btnTitle, onBtnClick, page, cartItems, disableNext, onSaveClick, isSubmitting } = props;
    const dispatch = useDispatch<AppDispatch>();
    const { totalNet, totalPrice } = calculatePricingInfo(cartItems);
    //page variable
    const availablePrice = totalPrice >= totalNet;
    useEffect(() => {
        dispatch(checkPayment());
    }, []);

    return (
        <Price totalPrice={totalPrice.toFixed(2)}>
            <div className="wrapper">
                <div className="totalPrice">
                    <span className="text">Total Price</span>
                    <span className="value">
                        <div className="value_input">${totalPrice.toFixed(2)}</div>
                    </span>
                </div>
                {page !== "payment" ? <div className="line"></div> : <div className="dash"></div>}
                {!availablePrice && (
                    <StyledError>
                        Error: The total sell price must be higher than the total net price
                    </StyledError>
                )}
                {page !== "payment" && (
                    <>
                        <StyledBorderButton
                            className="saveButton"
                            color="transparent"
                            borderColor="#31B4B9"
                            textColor="#31B4B9"
                            borderRadius="4px"
                            onClick={onSaveClick}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? "Saving ..." : "Save"}
                        </StyledBorderButton>
                        <ButtonCheckout
                            borderRadius="4px"
                            color={["#436CB2 0%", "#28B7C3 100%"]}
                            disabled={disableNext}
                            onClick={onBtnClick}
                        >
                            {btnTitle}
                        </ButtonCheckout>
                    </>
                )}
            </div>
        </Price>
    );
});

const Price = styled.div<{ totalPrice: string }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    .saveButton {
        display: none;
        ${breakpoint.breakOnlyMobile`
            display: block;
        `}
    }

    ${breakpoint.breakIpad`
            width: 260px;
    `}

    .wrapper {
        background-color: #fff;
        padding: 20px 16px;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding-bottom: 12px;
            width: ${(p) => (p.totalPrice.length > 10 ? "315px" : "285px")};
        }
        ${breakpoint.breakLarge`
            width: 350px;
        `}
    }

    .line {
        height: 1px;
        border-bottom: 1px solid #bdbdbd;
        margin: 20px 0;
    }

    .totalPrice {
        display: flex;
        align-items: center;
        justify-content: space-between !important;
        .text {
            font-size: 16px;
            font-weight: 500;
            line-height: 32px;
        }

        .value {
            font-size: 20px;
            font-weight: 700;
            line-height: 32px;
            color: ${(props) => props.theme.colors.main};
            background-color: #f2f2f2;
            display: flex;
            padding: 8px 10px 8px 10px;
            ${breakpoint.breakIpad`
                padding: 8px 10px 8px 30px;
                height:34px;
                display: flex;
                align-items: center;
            `}
            .value_input {
                display: inline-block;
                min-width: 61px;
                align-items: end;
                input {
                    width: ${(p) => p.totalPrice.length * 13 + "px"};
                    color: ${(props) => props.theme.colors.main};
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }

        .textFooter {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            background-color: #fff;
            color: #4f4f4f;
            padding: 19px 16px 31px 16px;
            ${breakpoint.breakOnlyMobile`
                flex: 1;
            `}
        }

        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) and (max-width: ${(p) =>
                p.theme.breakPoints.breakLarge}) {
            flex: 350;

            table {
                .field {
                    min-width: 100px;
                }

                .field,
                .value {
                    font-size: 12px;
                }

                .commission {
                    font-size: 12px;
                    min-width: 44px;
                }
            }

            .line {
                margin: 10px 0;
            }

            .totalPrice {
                margin: 10px 0;

                .text,
                .value {
                    font-size: 13px;
                }
            }

            .textFooter {
                font-size: 12px;
                line-height: 19px;
                padding: 0px 16px 19px 16px;
            }
        }
    }
`;

const ButtonCheckout = styled(StyledButton)`
    margin-top: 18px;
    margin-bottom: 18px;
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) and (max-width: ${(p) =>
            p.theme.breakPoints.breakLarge}) {
        height: 35px;
    }
`;
