import styled from "styled-components";
import {
    BreadcrumbsCommon,
    ClipLoaderCommon,
    MenuAccountComponent,
    StyledButton,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_HOME, PATH_LINK_AGENT_ACCOUNT } from "@routes";
import { breakpoint } from "@utilities";
import { IAgentInfoV2, IAgentStatusResponse } from "@interfaces";
import { authApi } from "@api";
import { useEffect, useState } from "react";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { SuccessToast } from "src/Components/toasts/SuccessToast";
import { useDispatch } from "react-redux";
import {
    AppDispatch,
    clearAgentToken,
    fetchAgentDetail,
    RootState,
    selectAuth,
    toggleLoading,
    useAppSelector,
} from "@redux";
import { useSelector } from "react-redux";
import _ from "lodash";
import { AgentLoginUpdateForm } from "./AgentUpdateLoginForm";
import { AgentLoginForm } from "./AgentLoginForm";

const AgentInfo = ({
    label,
    data,
    onLinkAgain,
}: {
    label: string;
    data: string | JSX.Element | null;
    onLinkAgain?: () => void;
}) => (
    <div className="agent-info">
        <p className="label">{label}</p>
        {data === "empty" ? (
            <button
                style={{
                    background:
                        "linear-gradient(109.6deg, rgb(255, 219, 47) 11.2%, rgb(244, 253, 0) 100.2%)",
                    color: "#512DA8",
                    border: "none",
                    padding: "8px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontWeight: "bold",
                }}
                onClick={onLinkAgain}
            >
                Link Again
            </button>
        ) : (
            <p
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }}
                className="data"
            >
                {data}
            </p>
        )}
    </div>
);

const AgentDetails = ({
    agentStatusData,
    onUnlink,
    onLinkAgain,
}: {
    agentStatusData: IAgentStatusResponse;
    onUnlink: () => void;
    onLinkAgain: () => void;
}) => {
    const [loading, setLoading] = useState(false);
    const formattedDate = `${new Date(agentStatusData.data.created_at).getFullYear()}/${new Date(agentStatusData.data.created_at).toLocaleString("en-US", { month: "short" })}/${_.padStart(String(new Date(agentStatusData.data.created_at).getDate()), 2, "0")}`;

    const agentInfoData = [
        { label: "Username", data: agentStatusData.data?.email || "-" },
        { label: "Bank BSB", data: agentStatusData.data?.bank_bsb || "-" },
        { label: "Bank Account", data: agentStatusData.data?.bank_account || "-" },
        {
            label: "Bank Country Short Code",
            data: agentStatusData.data?.bank_country_short_code || "-",
        },
        { label: "Linked On", data: `${formattedDate}` },
        { label: "Status", data: <div>Active</div> },
    ];

    const handleUnlinkAgent = async () => {
        setLoading(true);
        try {
            await authApi.unLinkAgent();
            onUnlink();
        } catch (error) {
            ErrorToast({ Message: `Error unlinking agent: ${error}` });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <p className="text-tag">TDMS Detail</p>
            <div className="agent-card">
                {agentInfoData.map((item, index) => (
                    <AgentInfo
                        key={index}
                        label={item.label}
                        data={item.data}
                        onLinkAgain={onLinkAgain}
                    />
                ))}
            </div>
            <StyledButton
                color={["#436CB2 0%", "#28B7C3 100%"]}
                className="submit_account"
                type="button"
                borderRadius="4px"
                onClick={handleUnlinkAgent}
                disabled={loading}
            >
                Unlink
            </StyledButton>
        </Container>
    );
};

export const LinkAgentAccount = () => {
    const breadcrumbsData = [
        { title: "Home", link: PATH_HOME },
        { title: "Link Agent Account", link: PATH_LINK_AGENT_ACCOUNT },
    ];

    const [agentStatusData, setAgentStatusData] = useState<IAgentStatusResponse | null>(null);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const initialValues: IAgentInfoV2 = { username: "", password: "" };
    const { isAgentTokenInvalid } = useAppSelector(selectAuth);

    useEffect(() => {
        const fetchTokenInfo = async () => {
            dispatch(toggleLoading(true));
            try {
                const tokenInfoAuth = (await dispatch(fetchAgentDetail())) as {
                    payload: IAgentStatusResponse;
                };
                setAgentStatusData(tokenInfoAuth.payload);
            } catch (err) {
                console.error("Error fetching agent info:", err);
            } finally {
                dispatch(toggleLoading(false));
            }
        };
        fetchTokenInfo();
    }, []);

    const handleLogin = (data: IAgentStatusResponse) => {
        setAgentStatusData(data);
        setShowUpdateForm(false);
    };

    const handleUnlink = () => {
        setAgentStatusData(null);
        dispatch(clearAgentToken());
        SuccessToast({ Message: "Agent Unlinked Successfully" });
    };

    const handleLinkAgain = () => {
        setShowUpdateForm(true);
    };

    const { isLoading } = useSelector((state: RootState) => state.loading);

    return (
        <AppModuleLayout>
            {isLoading ? (
                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
            ) : (
                <StyledMyAccountPage>
                    <div className="wrapper">
                        <div className="breadCrumbs">
                            <BreadcrumbsCommon data={breadcrumbsData} />
                        </div>
                        <div className="menu">
                            <MenuAccountComponent />
                        </div>
                        <div className="my_account">
                            {showUpdateForm ? (
                                <p className="heading1">Update Agent Account</p>
                            ) : (
                                <p className="heading1">Link Agent Account</p>
                            )}
                            <FormContainer>
                                {isAgentTokenInvalid && agentStatusData?.data?.email ? (
                                    <AgentLoginUpdateForm
                                        username={agentStatusData.data.email}
                                        onUpdate={handleLogin}
                                    />
                                ) : agentStatusData?.title === "Needs agent integration" ?(
                                    <AgentLoginForm
                                        initialValues={initialValues}
                                        onLogin={handleLogin}
                                    />
                                ) : agentStatusData?.data ? (
                                    <AgentDetails
                                        agentStatusData={agentStatusData}
                                        onUnlink={handleUnlink}
                                        onLinkAgain={handleLinkAgain}
                                    />
                                ) : null}
                            </FormContainer>
                        </div>
                    </div>
                </StyledMyAccountPage>
            )}
        </AppModuleLayout>
    );
};
const StyledMyAccountPage = styled.div`
    background-color: #f2f2f2;
    min-height: calc(100vh - 95px);
    ${breakpoint.breakOnlyMobile`
    background-color:#fff;
    `}

    .wrapper {
        display: block;
        margin: 0 auto;
        position: relative;

        ${breakpoint.breakTablet`
        display: flex;
        width: ${(p) => p.theme.widths.wide_700};
        margin-top: 81px;
        padding-bottom: 20px;
    `}

        ${breakpoint.breakIpadPro`
        width: ${(p) => p.theme.widths.wide_900};
    `}

    ${breakpoint.breakLarge`
        width: ${(p) => p.theme.widths.wide_1110};
    `}

    .breadCrumbs {
            position: absolute;
            bottom: calc(100% + 30px);
            display: none;

            ${breakpoint.breakTablet`
            display: block;
        `}
        }

        .menu {
            display: none;

            ${breakpoint.breakTablet`
            display: block;
        `}
        }
    }

    .my_account {
        padding: 24px 16px;
        background-color: #f2f2f2;
        position: relative;
        top: 11px;
        margin-bottom: 111px;
        ${breakpoint.breakOnlyMobile`
         background-color:#fff;
       `}

        ${breakpoint.breakTablet`
        flex: 1;
        padding: 0;
    `}

        .heading1 {
            font-size: 28px;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 26px;
            margin-top: -10px;
            ${breakpoint.breakTablet`
            display: block;
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
        `}
        }
    }
`;

const FormContainer = styled.div`
    background-color: #fff;
    padding: 32px 32px 45px 32px;
    ${breakpoint.breakOnlyMobile`
    background-color: transparent;
    padding: 0px;
      
    `}
`;

const Container = styled.form`
    .submit_account {
        width: 32.5%;
        margin: auto;
        margin-top: 50px;
        ${breakpoint.breakOnlyMobile`
        width: 100%;
        margin-top: 30px;
        `};
    }
    .text-tag {
        font-weight: 600;
        margin-bottom: 30px;
        margin-top: 20px;
    }
    .agent-card {
        padding: 14px 0;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        color: #090a18;
        .agent-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            &:not(:last-child) {
                border-bottom: 1px solid #c5c7c9; /* Divider after each row except the last one */
            }
            .label {
                width: 30%;
            }
            .data {
                width: 70%;
                text-align: right;
            }
        }
    }
`;
