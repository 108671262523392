import styled from "styled-components";

import { breakpoint } from "@utilities";

export const StyledBanner = styled.div`
    .bannerMobile {
        width: 100vw;
        position: relative;

        ${breakpoint.breakTablet`
            display: none;
        `}

        img {
            width: 100%;
        }

        .heading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            text-align: center;
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
            color: #fff;
        }
    }

    .bannerDesktop {
        display: none;
        height: 85vh;
        width: 100vw;
        position: relative;

        ${breakpoint.breakTablet`
            display: block;
        `}

        img {
            height:100%;
            width: 100%;
            object-fit: cover;
        }

        .heading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 35px;
            font-weight: 700;
            line-height: 60px;
            color: #fff;
            max-width: 869px;

            ${breakpoint.breakIpad`
                font-size: 40px;
                line-height: 77px;
            `}

            ${breakpoint.breakLarge`
                font-size: 55px;
                line-height: 83px;
            `}
        }
    }
`;
