import { ICancelRequest } from "./../Interfaces/booking";
import { IFavoriteTour } from "./../Interfaces/tours";
import { ISendLogEmail } from "./../Interfaces/phpHelper";

import { IBooking, IAccountInfo, ISavedCart, IShareBookingRequest } from "@interfaces";
import { lsEmail } from "@utilities";
import authClientV2 from "src/Api/authClientV2";
import ftApiClient from "src/Api/ftApiClient";
import wtApiClient from "src/Api/wtApiClient";

export const phpHelper = {
    booking: (data: IBooking) => {
        ftApiClient.post("connect-payment", data);
    },
    saveAccount: async (json: IAccountInfo) => {
        const email = lsEmail.getItem();
        const body = {
            email,
            json,
        };
        await ftApiClient.post("save-account", body);
    },
    getAccount: async () => {
        const email = lsEmail.getItem();
        return await ftApiClient.get(`detail-account/${email}`);
    },
    saveQuotes: (json: ISavedCart[]) => {
        const email = lsEmail.getItem();
        const body = {
            email,
            json,
        };
        authClientV2.post("save-quote", body);
    },
    // getQuotes: () => {
    //     const email = lsEmail.getItem();
    //     return ftApiClient.get(`detail-quote/${email}`);
    // },
    getQuotes: (email?: string) => {
        const encodedEmail = email ? encodeURIComponent(email) : "";
        const url = `/customerOrderDetail?email=${encodedEmail}&searchOnlyStatus=Quote`;
        return wtApiClient.get(url);
    },
    getQuotesDetail: (bookingReference: string) => {
        const url = `booking/${bookingReference}/detail`;
        return ftApiClient.get(url);
    },
    getVoucher: (orderId: string) => {
        const url = `voucher/${orderId}.json`;
        return wtApiClient.get(url);
    },
    sendLogEmail: (data: ISendLogEmail) => {
        ftApiClient.post("email-log", data);
    },
    fetchFavorite: () => {
        const email = lsEmail.getItem();
        return ftApiClient.get(`detail-favorite/${email}`);
    },
    saveFavorite: (json: IFavoriteTour[]) => {
        const email = lsEmail.getItem();
        const body = {
            email,
            json,
        };
        ftApiClient.post("save-favorite", body);
    },

    deleteFavorite: (payload: IFavoriteTour) => {
        const email = lsEmail.getItem();
        const body = {
            email,
            productId: payload.productId,
        };

        return ftApiClient.post(`favourites/delete`, body);
    },
    addFavorite: (payload: IFavoriteTour) => {
        const email = lsEmail.getItem();
        const body = {
            email,
            productId: payload.productId,
            productImagePath: payload.productImagePath,
            rrp: payload.rrp,
            date: payload.date,
            duration: payload.duration,
            location: payload.location,
            name: payload.name,
        };

        return ftApiClient.post(`favourites/add`, body);
    },
    getFavourites: () => {
        const email = lsEmail.getItem();
        return ftApiClient.get(`favourites/${email}`);
    },
    getFavorite: (page: number, limit: number) => {
        const email = lsEmail.getItem();
        const queryString = `?page=${page}&limit=${limit}`;
        return ftApiClient.get(`favorites/${email}${queryString}`);
    },
    getCancelRequests: () => {
        return ftApiClient.get(`cancel-requests`);
    },
    getCancelRequest: (bookingReference: string) => {
        return ftApiClient.get(`cancel-requests/${bookingReference}`);
    },
    cancelRequest: (payload: ICancelRequest) => {
        const email = lsEmail.getItem();
        const body = {
            ...payload,
            email,
        };
        return ftApiClient.post("cancel-requests", body);
    },
    resendVoucher: (orderId: string) => {
        return ftApiClient.post(`resend-voucher/${orderId}`);
    },
    shareToCustomer: (data: IShareBookingRequest) => {
        return ftApiClient.post("share-booking", data);
    },
};
