import styled from "styled-components";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { ClipLoaderCommon, ConfirmComponent, ProcessOrderComponent } from "@components";
import { CartConstants, DURATION_TIME } from "@constants";
import { CartModule, CheckoutModule, OrderSuccessModule, PaymentModule } from "@module";
import {
    setStep,
    setCurrentStep,
    useAppSelector,
    selectCart,
    openToast,
    AppDispatch,
    removeCartAndFetch,
} from "@redux";
import { AppModuleLayout } from "@layouts";
import { breakpoint } from "@utilities";
import { NavLink } from "react-router-dom";
import { LoadingStage } from "src/Interfaces/shared";
import { ErrorToast } from "src/Components/toasts/ErrorToast";

export const Cart = () => {
    //page hook
    const dispatch = useDispatch<AppDispatch>();
    //redux state
    const { step, currentStep, carts, cartItemsLoadingStage, customersLoadingStage } =
        useAppSelector(selectCart);
    //page state
    const [currentPoint, setCurrentPoint] = useState(step);
    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    const [cartDeleteId, setCartDeleteId] = useState<string | number>("");
    //page variable
    const { CHECKOUT, ORDER_SUCCESS, PAYMENT } = CartConstants;

    //scroll on top
    useEffect(() => {
        setCurrentPoint(step);
        scrollToTop();
    }, [step]);

    useEffect(() => {
        if (cartItemsLoadingStage === LoadingStage.INITIALIZE_FAILURE) {
            ErrorToast({ Message: "Failed to load items" });
        }
    }, [cartItemsLoadingStage]);

    useEffect(() => {
        if (customersLoadingStage === LoadingStage.INITIALIZE_FAILURE) {
            ErrorToast({ Message: "Failed to load customers" });
        }
    }, [customersLoadingStage]);

    // WHAT: empty cart
    useEffect(() => {
        if (carts.length === 0) {
            dispatch(
                openToast({
                    type: "error",
                    message: `Cart is empty`,
                    autoHideDuration: DURATION_TIME,
                }),
            );
        }
    }, [carts.length]);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const moveTo = (step: number) => {
        dispatch(setStep(step));
        if (step === currentStep + 1) {
            dispatch(setCurrentStep(step));
        }
    };

    const renderComponent = () => {
        switch (step) {
            case CHECKOUT:
                return <CheckoutModule moveTo={moveTo} />;
            case ORDER_SUCCESS:
                return <OrderSuccessModule />;
            case PAYMENT:
                return <PaymentModule moveTo={moveTo} />;
            default:
                return <CartModule moveTo={moveTo} handleDeletePopUp={handleDeletePopUp} />;
        }
    };

    const handleHeaderBack = () => {
        if (step > 1) {
            dispatch(setStep(step - 1));
            return;
        }
        window.history.back();
    };

    const handleDeletePopUp = (cartDeleteId?: string | number) => {
        setIsShowPopup(!isShowPopup);
        setCartDeleteId(cartDeleteId || "");
    };

    const handleDeleteProduct = () => {
        handleDeletePopUp();
        if (cartDeleteId !== "") dispatch(removeCartAndFetch(cartDeleteId));
    };
    return (
        <AppModuleLayout onBack={handleHeaderBack}>
            <ProcessOrderComponent currentPoint={currentPoint} />
            <Main>
                {cartItemsLoadingStage === LoadingStage.IS_INITIALIZING ||
                customersLoadingStage === LoadingStage.IS_INITIALIZING ? (
                    <Container className="wrapper">
                        <Content>
                            <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
                        </Content>
                    </Container>
                ) : carts.length === 0 ? (
                    <Container className="wrapper">
                        <Content>
                            <img
                                width={65}
                                height={65}
                                src="/images/cart/emptyCart.svg"
                                alt="emptyCartLogo"
                            />
                            <p>There are no items in the Cart</p>
                            <NavLink to="/">
                                <Button>Continue Browsing</Button>
                            </NavLink>
                        </Content>
                    </Container>
                ) : (
                    renderComponent()
                )}
                <ConfirmComponent
                    isShowPopup={isShowPopup}
                    onClickOverlay={handleDeletePopUp}
                    onClickBtnYes={handleDeleteProduct}
                    onClickBtnNo={handleDeletePopUp}
                >
                    Are you sure you want to delete this fare?
                </ConfirmComponent>
            </Main>
        </AppModuleLayout>
    );
};

const Main = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    ${breakpoint.breakTablet`
        padding-bottom: 30px;
    `}

    .clipLoader {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.2);

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .loader {
            z-index: 1;
        }
    }
`;

const Container = styled.div`
    background-color: white;
    display: flex;
    flex: 1;
    ${breakpoint.breakMobile`
        width: ${(p) => p.theme.widths.wide_700}
    `}

    ${breakpoint.breakTablet`
        width: ${(p) => p.theme.widths.wide_700};
    `}

    ${breakpoint.breakIpadPro`
        width: ${(p) => p.theme.widths.wide_900};
    `}

    ${breakpoint.breakLarge`
        width: ${(p) => p.theme.widths.wide_1110};
    `}
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 14px;
`;

const Button = styled.button`
    border: 1px solid #31b4b9;
    padding: 12px 24px;
    border-radius: 4px;
    color: #31b4b9;
    background: none;
    cursor: pointer;

    &:hover {
        background-color: #e0f7f8;
    }
`;
